import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import swal from "sweetalert";
import { createGlobalStyle } from "styled-components";
import {
  SearchOutlined,
  ClearOutlined,
  PaperClipOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Card,
  Form,
  ConfigProvider,
  Space,
  Button,
  Table,
  Typography,
  Select,
  Tag
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import moment from "moment";
import "moment/locale/th";

import * as xlsx from "xlsx";

dayjs.locale("th");
dayjs.extend(buddhistEra);

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const Research_report1_2 = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();
  const [xsummary, _xsummary] = useState(0);

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  const [pageTable, _pageTable] = useState(1);
  const [pageSizeTable, _pageSizeTable] = useState(100);

  const [xStatus, _xStatus] = useState("");
  const [xStatusValue, _xStatusValue] = useState("0");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหาสถานะโครงการ
          axios
            .get(xURL + "Project_status")
            .then(result => {
              //console.log(result.data);
              let Tb = [];

              result.data.forEach((i, idx) => {
                if (i.id == "I" || i.id == "C") {
                  Tb.push({ id: i.id, name: i.name });
                }
              });

              _xStatus(Tb);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          Finddata(xStatusValue);
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const Finddata = zstatus => {
    axios
      .post(xURL + "find_report1_2", { status: zstatus })
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        let xsum = 0;

        let xnum = 0,
          xcmumis_id = "";

        result.data.forEach((i, idx) => {
          let xmoney_bath = 0;

          if (i.cmumis_id !== xcmumis_id) {
            xnum = xnum + 1;
          }

          if (!i.money_bath) {
            xmoney_bath = 0;
          } else {
            xmoney_bath = i.money_bath;
          }

          let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          let xstatus = "",
            xcolor = "";

          if (i.status_id === "P") {
            xcolor = "gold";
          } else if (i.status_id === "I") {
            xcolor = "geekblue";
          } else if (i.status_id === "C") {
            xcolor = "green";
          } else if (i.status_id === "F") {
            xcolor = "red";
          } else if (i.status_id === "S") {
            xcolor = "red";
          }

          xstatus = (
            <Tag color={xcolor} key={i.status_id}>
              {i.status_id.toUpperCase()}
            </Tag>
          );

          Tb1.push({
            key: idx,
            no: xnum,
            cmumis_id: i.cmumis_id,
            name_P: i.name_thai,
            nameshort: i.short_name,
            researcher_name: i.researchname,
            start_date: xsdate,
            end_date: xedate,
            money_b: xmoney_bath.toLocaleString(undefined, {
              minimumFractionDigits: 2
            }),
            detail: i.detail,
            remark: i.remark,
            status: xstatus
          });

          Tb2.push({
            cmumis_id: i.cmumis_id,
            name_P: i.name_thai,
            nameshort: i.short_name,
            researcher_name: i.researchname,
            start_date: xsdate,
            end_date: xedate,
            status: i.status_id,
            money_b: xmoney_bath,
            detail: i.detail,
            remark: i.remark
          });

          xcmumis_id = i.cmumis_id;

          xsum = xsum + parseFloat(xmoney_bath);
        });

        _dataSource1(Tb1);
        _dataSourceEx(Tb2);

        _xsummary(
          xsum.toLocaleString(undefined, {
            minimumFractionDigits: 2
          })
        );
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcel = () => {
    let xdata = [],
      xtabtitle = "",
      header = [];

    xtabtitle = "รายงานเงินรับเข้าโครงการวิจัย";

    header.push(
      "รหัส CMU MIS",
      "ชื่อโครงการ",
      "ชื่อย่อโครงการ",
      "หัวหน้าโครงการ",
      "วันที่เริ่มต้น",
      "สิ้นสุด",
      "สถานะโครงการวิจัย",
      "จำนวนเงิน",
      "รายละเอียด",
      "หมายเหตุ"
    );

    xdata.push([xtabtitle]);

    xdata.push([""]);
    xdata.push(header);

    let excmumis_id = "",
      exnameP = "",
      exnameshort = "",
      exresearchname = "",
      exsdate = "",
      exedate = "",
      exstatus = "";

    for (var row in dataSourceEx) {
      const xx = [];

      let xchk = 0;

      for (var col in dataSourceEx[row]) {
        if (col == "cmumis_id") {
          if (excmumis_id == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (dataSourceEx[row]["cmumis_id"] == excmumis_id) {
              xx.push("");

              xchk = 1;
            } else {
              xx.push(dataSourceEx[row][col]);

              xchk = 0;
            }
          }

          excmumis_id = dataSourceEx[row]["cmumis_id"];
        } else if (col == "name_P") {
          if (exnameP == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exnameP = dataSourceEx[row]["name_P"];
        } else if (col == "nameshort") {
          if (exnameshort == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exnameshort = dataSourceEx[row]["nameshort"];
        } else if (col == "researcher_name") {
          if (exresearchname == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exresearchname = dataSourceEx[row]["researcher_name"];
        } else if (col == "start_date") {
          if (exsdate == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exsdate = dataSourceEx[row]["start_date"];
        } else if (col == "end_date") {
          if (exedate == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exedate = dataSourceEx[row]["end_date"];
        } else if (col == "status") {
          if (exstatus == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exstatus = dataSourceEx[row]["status"];
        } else {
          xx.push(dataSourceEx[row][col]);
        }
      }

      xdata.push(xx);
    }

    xdata.push([
      "",
      "",
      "",
      "",
      "",
      "",
      "รวมทั้งหมด",
      parseFloat(xsummary.replace(",", "").replace(",", ""))
    ]);

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "รายงานเงินงวดค้างรับ.xlsx");
  };

  const GoPage = cmumis_id => {
    window.open("/Research_main?cmumis_id=" + cmumis_id, "_blank");
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 50,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "รหัส CMU MIS",
      dataIndex: "cmumis_id",
      key: "cmumis_id",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                GoPage(text);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "name_P",
      key: "name_P",
      width: 380,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                GoPage(record.cmumis_id);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อย่อโครงการ",
      dataIndex: "nameshort",
      key: "nameshort",
      width: 150,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                GoPage(record.cmumis_id);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "หัวหน้าโครงการ",
      dataIndex: "researcher_name",
      key: "researcher_name",
      align: "center",
      width: 150,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: __TBShowText(text, 0, 1),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      width: 110,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "สิ้นสุด",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      width: 110,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "สถานะโครงการวิจัย",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "money_b",
      key: "money_b",
      align: "center",
      width: 100,
      render: (text, record) => __TBShowText(record.money_b, 1, 0)
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      width: 300
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 300
    }
  ];

  function handleChange(value) {
    //console.log(`selected ${value}`);

    Finddata(value);
    _xStatusValue(value);
  }

  const pageOnChange = (zpage, zpageSize) => {
    _pageTable(zpage);
    _pageSizeTable(zpageSize);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="รายงานเงินงวดค้างรับ"
            />
            <Divider />
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <Form.Item label="สถานะโครงการ" name="slstatus">
              <Select
                defaultValue="สถานะทั้งหมด"
                style={{ width: "380px" }}
                onChange={handleChange}
              >
                {xStatus.length > 0
                  ? xStatus.map((i, idx) => {
                      return (
                        <Option value={i.id}>
                          {i.id} : {i.name}
                        </Option>
                      );
                    })
                  : <Option value="0">สถานะทั้งหมด</Option>}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Col span={23}>
          <Row style={{ float: "right" }}>
            <Button ghost type="success" onClick={bExcel}>
              <FileExcelOutlined /> Excel
            </Button>
          </Row>
        </Col>
        <br />
        <br />

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
              summary={() =>
                <Table.Summary fixed>
                  <Table.Summary.Row
                    style={{
                      textAlign: "right",
                      backgroundColor: "#b5f5ec"
                    }}
                  >
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell /> <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell align="center">
                      รวมทั้งหมด
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>{xsummary}</Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                  </Table.Summary.Row>
                </Table.Summary>}
            />
          </Col>
        </Row>
      </Content>

      <div style={{ height: "80px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_report1_2;
