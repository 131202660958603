import React, { useEffect, useState } from "react";

import "./css/dashboard.css";

import {
  Row,
  Col,
  Layout,
  Card,
  Divider,
  Space,
  Select,
  Button,
  Form,
  Modal,
  InputNumber
} from "antd";

import moment from "moment";

const { Option } = Select;

const Dashboard_r = () => {
  const [form1] = Form.useForm();

  let YearNow = moment().format("YYYY");

  const [YearValue, _YearValue] = useState(YearNow);
  const [xYeardh, _xYeardh] = useState([]);

  const handleChange = value => {
    // console.log(`selected ${value}`);

    _YearValue(value);
  };

  return (
    <React.Fragment>
      <div>
        <h1 className="rise">R</h1>
      </div>

      <Row>
        <div>
          <Form.Item label="Year" name="slyear">
            <Select
              defaultValue={YearNow}
              style={{
                width: 250,
                marginBottom: "20px"
              }}
              onChange={handleChange}
            >
              {xYeardh.map((i, idx) => {
                return (
                  <Option value={i.year}>
                    {i.year} ({i.name})
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </Row>

      <Row gutter={3}>
        <Col xs={23} sm={12} md={12} lg={8} xl={6}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR12 Total Research Funding
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: ""
                    }}
                  >
                    0
                  </p>
                </Row>
              </Col>

              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  0
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={6}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR 13 Other Income
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: ""
                    }}
                  >
                    0
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  0
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={6}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Int. Funding
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: ""
                    }}
                  >
                    0
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  0
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={6}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Thai Funding
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: ""
                    }}
                  >
                    0
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  0
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard_r;
