import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import {
  Row,
  Form,
  Menu,
  Affix,
  Button,
  Space,
  Drawer,
  Card,
  Input,
  Divider,
  Avatar,
  Select
} from "antd";
import {
  PoweroffOutlined,
  UserOutlined,
  MenuOutlined,
  FileSearchOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  SettingOutlined,
  CloseOutlined,
  SaveOutlined,
  TeamOutlined,
  FileTextOutlined,
  InteractionOutlined,
  BankOutlined,
  PieChartOutlined,
  FundOutlined,
  PlusSquareOutlined,
  ReadOutlined,
  SolutionOutlined
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";

import "./index.css";

const { Option } = Select;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const Header_title = props => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [visible, _Visible] = useState(false);
  const [visible2, _Visible2] = useState(false);

  const [xtitle_name, _xtitle_name] = useState("");
  const [xfirst_name_th, _xfirst_name_th] = useState("");
  const [xlast_name_th, _xlast_name_th] = useState("");
  const [xfirst_name_eng, _xfirst_name_eng] = useState("");
  const [xlast_name_eng, _xlast_name_eng] = useState("");
  const [xemp, _xemp] = useState("");
  const [xposition, _xposition] = useState("");
  const [xproject_position, _xproject_position] = useState("");
  const [xcenter, _xcenter] = useState("");
  const [xemail, _xemail] = useState("");
  const [xphone, _xphone] = useState("");
  const [xline, _xline] = useState("");

  const [show1, _show1] = useState(true);

  const [xEmp, _xEmp] = useState("");
  const [xPosition, _xPosition] = useState("");
  const [xProject_Position, _xProject_Position] = useState("");
  const [xCenter, _xCenter] = useState("");

  const [xEmpV, _xEmpV] = useState("");
  const [xPositionV, _xPositionV] = useState("");
  const [xProject_PositionV, _xProject_PositionV] = useState("");
  const [xCenterV, _xCenterV] = useState("");

  //const toggleButton = document.getElementsByClassName("toggle-button")[0];
  const navbarLinks = document.getElementsByClassName("navbar-links")[0];

  const [levelU, _levelU] = useState("");

  useEffect(() => {
    // console.log(props.research_id);

    axios
      .post(xURL + "findlevel", { research_id: props.research_id })
      .then(result => {
        // console.log(result.data.length);
        if (result.data.length > 0) {
          if (
            result.data[0].user_level_id == 4 ||
            result.data[0].user_level_id == 3
          ) {
            _levelU("research");
          } else if (result.data[0].user_level_id == 7) {
            _levelU("student");
          } else if (
            result.data[0].user_level_id == 6 ||
            result.data[0].user_level_id == 99
          ) {
            _levelU("admin");
          } else {
            _levelU("");
          }
        } else {
          _levelU("");
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const toggleClick = () => {
    navbarLinks.classList.toggle("active");
  };

  const onLogout = () => {
    //console.log("test");
    swal({
      title: "ยืนยัน ออกจากระบบ",
      text: ":-(",
      icon: "warning",
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(logoutclick => {
      if (logoutclick) {
        swal({
          title: "แจ้งเตือน!",
          text: "Logout สำเร็จ",
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(() => {
          localStorage.removeItem("token");
          window.location = "/";
        });
      }
    });
  };

  // const bmailclick = () => {
  //   if (!xemail) {
  //     swal("แจ้งเตือน", "ยังไม่ได้เพิ่ม E-mail", "warning");
  //   } else {
  //     axios
  //       .post(xURL + "alert_mail", { email: xemail })
  //       .then(result => {})
  //       .catch(function(error) {
  //         // handle error
  //         console.log("problem here", error);
  //       });
  //   }
  // };

  const Editdata = () => {
    form1.setFieldsValue({
      edtitle_name2: xtitle_name,
      edfirst_name_th2: xfirst_name_th,
      edlast_name_th2: xlast_name_th,
      edfirst_name_eng2: xfirst_name_eng,
      edlast_name_eng2: xlast_name_eng,
      slemp: xemp,
      slposition: xposition,
      slproject_position: xproject_position,
      slcenter: xcenter,
      edemail2: xemail,
      edphone2: xphone,
      edline2: xline
    });

    _show1(false);
  };

  const SaveEditdata = () => {
    const f = form1.getFieldValue();

    if (!f.edtitle_name2) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ ชื่อนำ!", "warning");
    } else if (!f.edfirst_name_th2) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ ชื่อ ภาษาไทย!", "warning");
    } else if (!f.edlast_name_th2) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ นามสกุล ภาษาไทย!", "warning");
    } else {
      swal({
        title: "ยืนยัน แก้ไขข้อมูลส่วนตัว",
        text: ":-3",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(props.research_id)
          updatedata();

          _xEmpV("");
          _xPositionV("");
          _xProject_PositionV("");
          _xCenterV("");

          _show1(true);
        }
      });
    }
  };

  const updatedata = () => {
    const f = form1.getFieldValue();

    //console.log(xEmpV, xPositionV, xProject_PositionV, xCenterV);

    axios
      .post(xURL + "update", {
        research_id: props.research_id,
        title_name: f.edtitle_name2,
        first_name_th: f.edfirst_name_th2,
        last_name_th: f.edlast_name_th2,
        first_name_eng: f.edfirst_name_eng2,
        last_name_eng: f.edlast_name_eng2,
        emp: xEmpV,
        position: xPositionV,
        project_position: xProject_PositionV,
        center: xCenterV,
        email: f.edemail2,
        phone: f.edphone2,
        line: f.edline2,
        xcheck: 17
      })
      .then(() => {
        showDrawer2();
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const showDrawer = () => {
    _Visible(true);
  };

  const showDrawer2 = () => {
    //console.log("id=", props.research_id);
    let xresearch_id = props.research_id;

    //ค้นหา emp
    axios
      .get(xURL + "research_emp")
      .then(result => {
        //console.log(result.data);

        _xEmp(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    //ค้นหา position
    axios
      .get(xURL + "research_position")
      .then(result => {
        //console.log(result.data);

        _xPosition(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    //ค้นหา project_position
    axios
      .get(xURL + "research_project_position")
      .then(result => {
        //console.log(result.data);

        _xProject_Position(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    //ค้นหา center
    axios
      .get(xURL + "center")
      .then(result => {
        //console.log(result.data);

        _xCenter(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    if (xresearch_id !== "null") {
      axios
        .post(xURL + "find_userdata", {
          research_id: xresearch_id
        })
        .then(result => {
          //console.log("data =", result.data);

          if (result.data) {
            _xtitle_name(result.data[0].pre);
            _xfirst_name_th(result.data[0].name);
            _xlast_name_th(result.data[0].lname);
            _xfirst_name_eng(result.data[0].engname);
            _xlast_name_eng(result.data[0].englname);
            _xemp(result.data[0].empname);
            _xposition(result.data[0].positionname);
            _xproject_position(result.data[0].project_positionname);
            _xcenter(result.data[0].center_name);
            _xemail(result.data[0].email);
            _xphone(result.data[0].tel);
            _xline(result.data[0].line_noti);
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }

    _Visible2(true);
  };

  const onClose = () => {
    window.location.reload();
    _Visible(false);
  };

  const onClose2 = () => {
    _Visible2(false);
  };

  function handleChange(value) {
    _xEmpV(value);
  }

  function handleChange2(value) {
    _xPositionV(value);
  }

  function handleChange3(value) {
    _xProject_PositionV(value);
  }

  function handleChange4(value) {
    _xCenterV(value);
  }

  return (
    <Affix offsetTop={0} className="app__affix-header">
      <div>
        <nav className="navbar">
          <div
            className="brand-title"
            onClick={() => {
              if (levelU == "student") {
                window.location = "/Research_main";
              } else {
                window.location = "/Research_scopus";
              }
            }}
          >
            <Space>
              <img width={45} src="./RIHES_Logo-White.png" />

              <a
                style={{
                  fontWeight: "bold",
                  color: "white"
                }}
              >
                ระบบบริหารงานวิจัย
              </a>
            </Space>
          </div>

          <a href="#" class="toggle-button" onClick={toggleClick}>
            <span class="bar" />
            <span class="bar" />
            <span class="bar" />
          </a>

          <div className="navbar-links">
            <ul>
              {levelU == "research" || levelU == "student"
                ? <li />
                : <li>
                    <a href="/Research_dashboard">
                      <PieChartOutlined /> Dashboard
                    </a>
                  </li>}

              {levelU == "student"
                ? <li />
                : <li>
                    <a href="/Research_scopus">
                      <FileProtectOutlined /> SCOPUS
                    </a>
                  </li>}

              <li className="dropdown">
                <a className="dropbtn">
                  <SolutionOutlined /> โครงการวิจัย
                </a>
                <div class="dropdown-content">
                  <a href="/Research_main">
                    <FileSearchOutlined /> ค้นหาโครงการวิจัย
                  </a>

                  {levelU == "research"
                    ? <a href="#" disabled>
                        <FileAddOutlined /> เพิ่ม โครงการวิจัย
                      </a>
                    : <a href="/Research_input">
                        <FileAddOutlined /> เพิ่ม โครงการวิจัย
                      </a>}
                </div>
              </li>

              {levelU == "research" || levelU == "student"
                ? <li />
                : <li>
                    <a href="/Research_staff">
                      <TeamOutlined /> นักวิจัย / บุคลากร
                    </a>
                  </li>}

              <li>
                <a href="/Research_academic">
                  <BankOutlined /> สำนักวิชาฯ
                </a>
              </li>

              <li className="dropdown">
                <a className="dropbtn">
                  <ReadOutlined /> ทรัพย์สินทางปัญญา
                </a>
                <div class="dropdown-content">
                  {levelU == "research" || levelU == "student"
                    ? <a href="#" disabled>
                        <PlusSquareOutlined /> เพิ่ม/แก้ไข ทรัพย์สินทางปัญญา
                      </a>
                    : <a href="/Research_input_patent">
                        <PlusSquareOutlined /> เพิ่ม/แก้ไข ทรัพย์สินทางปัญญา
                      </a>}
                  <a href="/Research_report_patent">
                    <FileTextOutlined /> รายงานทรัพย์สินทางปัญญา
                  </a>
                </div>
              </li>

              <li className="dropdown">
                <a href="/Research_pms">
                  <FundOutlined /> PMS
                </a>
              </li>

              {levelU == "student"
                ? <li />
                : <li className="dropdown">
                    <a className="dropbtn">
                      <FileTextOutlined /> รายงาน
                    </a>
                    <div class="dropdown-content">
                      <a href="/Research_report">
                        <FileTextOutlined /> รายงานเงินรับเข้าโครงการวิจัย
                      </a>
                      <a href="/Research_report1_2">
                        <FileTextOutlined /> รายงานเงินงวดค้างรับ
                      </a>
                      <a href="/Research_report_to_cmu">
                        <FileTextOutlined /> รายงานการส่งเงินสมทบ
                      </a>
                      <a href="/Research_report1_1">
                        <FileTextOutlined />{" "}
                        รายงานเงินรับเข้าโครงการวิจัยรายโครงการ
                      </a>
                      <a href="/Research_report2">
                        <FileTextOutlined /> รายงานแสดงแหล่งทุน
                      </a>

                      <a href="/Research_report3">
                        <FileTextOutlined /> ผลลัพธ์ด้านผลิตภัณฑ์และกระบวนการ
                      </a>
                      <a href="#" disabled>
                        <FileTextOutlined /> ผลลัพธ์ด้านการเงิน ตลาด และกลยุทธ์
                      </a>
                      <a href="/Research_pub_type_cmu">
                        <InteractionOutlined /> ทิศทางการวิจัยของโครงการวิจัย
                      </a>
                    </div>
                  </li>}

              <li>
                <a onClick={showDrawer}>
                  สวัสดี {props.tname} <MenuOutlined />
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <Drawer
          title={props.tname}
          closable={false}
          onClose={onClose}
          visible={visible}
          width={250}
        >
          <Menu>
            <Menu.Item key="6" onClick={showDrawer2}>
              <UserOutlined /> ข้อมูลส่วนตัว
            </Menu.Item>

            <Menu.Item key="7" onClick={onLogout}>
              <PoweroffOutlined /> ออกจากระบบ
            </Menu.Item>
          </Menu>

          <Drawer
            title="ข้อมูลส่วนตัว"
            closable={false}
            onClose={onClose2}
            visible={visible2}
            width={window.innerWidth > 1200 ? 600 : "auto"}
            extra={
              <Button
                icon={<CloseOutlined />}
                onClick={() => {
                  _Visible2(false);
                }}
              />
            }
          >
            <Card>
              <Form form={form1} initialValues={{}} {...layout}>
                <Row justify="center">
                  <Avatar size={100} icon={<UserOutlined />} />
                </Row>

                <Divider />
                {show1
                  ? <div>
                      <Button
                        style={{ float: "right" }}
                        onClick={() => {
                          Editdata();
                        }}
                      >
                        <SettingOutlined /> แก้ไขข้อมูลส่วนตัว
                      </Button>
                      <br />
                      <br />
                      <br />

                      <Form.Item label="ชื่อนำ" name="edtitle_name">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "50%", color: "black" }}
                            value={xtitle_name}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item label="ชื่อ ภาษาไทย" name="edfirst_name_th">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xfirst_name_th}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item label="นามสกุล ภาษาไทย" name="edlast_name_th">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xlast_name_th}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item
                        label="ชื่อ ภาษาอังกฤษ"
                        name="edfirst_name_eng"
                      >
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xfirst_name_eng}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item
                        label="นามสกุล ภาษาอังกฤษ"
                        name="edlast_name_eng"
                      >
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xlast_name_eng}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item label="ระดับ" name="edemp">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xemp}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item label="ตำแหน่ง" name="edposition">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xposition}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item
                        label="ตำแหน่งนักวิจัย"
                        name="edproject_position"
                      >
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xproject_position}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item label="หน่วยงาน" name="edcenter">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xcenter}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item label="E-mail" name="edemail">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xemail}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item label="เบอร์ติดต่อ" name="edphone">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xphone}
                          />
                        </Row>
                      </Form.Item>

                      <Form.Item label="Line-Token" name="edline">
                        <Row>
                          <Input
                            disabled
                            style={{ width: "100%", color: "black" }}
                            value={xline}
                          />
                        </Row>
                      </Form.Item>
                    </div>
                  : <div>
                      <Button
                        style={{ float: "right" }}
                        onClick={() => {
                          SaveEditdata();
                        }}
                      >
                        <SaveOutlined /> บันทึกข้อมูล
                      </Button>
                      <br />
                      <br />
                      <br />

                      <Form.Item label="ชื่อนำ" name="edtitle_name2">
                        <Input allowClear style={{ width: "50%" }} />
                      </Form.Item>

                      <Form.Item label="ชื่อ ภาษาไทย" name="edfirst_name_th2">
                        <Input allowClear style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item label="นามสกุล ภาษาไทย" name="edlast_name_th2">
                        <Input allowClear style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        label="ชื่อ ภาษาอังกฤษ"
                        name="edfirst_name_eng2"
                      >
                        <Input allowClear style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        label="นามสกุล ภาษาอังกฤษ"
                        name="edlast_name_eng2"
                      >
                        <Input allowClear style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item label="ระดับ" name="slemp">
                        <Select
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        >
                          {xEmp.length > 0
                            ? xEmp.map((i, idx) => {
                                return (
                                  <Option value={i.id}>
                                    {i.name}
                                  </Option>
                                );
                              })
                            : <Option value="">
                                {}
                              </Option>}
                        </Select>
                      </Form.Item>

                      <Form.Item label="ตำแหน่ง" name="slposition">
                        <Select
                          onChange={handleChange2}
                          style={{ width: "100%" }}
                        >
                          {xPosition.length > 0
                            ? xPosition.map((i, idx) => {
                                return (
                                  <Option value={i.id}>
                                    {i.name}
                                  </Option>
                                );
                              })
                            : <Option value="">
                                {}
                              </Option>}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="ตำแหน่งนักวิจัย"
                        name="slproject_position"
                      >
                        <Select
                          onChange={handleChange3}
                          style={{ width: "100%" }}
                        >
                          {xProject_Position.length > 0
                            ? xProject_Position.map((i, idx) => {
                                return (
                                  <Option value={i.id}>
                                    {i.name}
                                  </Option>
                                );
                              })
                            : <Option value="">
                                {}
                              </Option>}
                        </Select>
                      </Form.Item>

                      <Form.Item label="หน่วยงาน" name="slcenter">
                        <Select
                          onChange={handleChange4}
                          style={{ width: "100%" }}
                        >
                          {xCenter.length > 0
                            ? xCenter.map((i, idx) => {
                                return (
                                  <Option value={i.id}>
                                    {i.name}
                                  </Option>
                                );
                              })
                            : <Option value="">
                                {}
                              </Option>}
                        </Select>
                      </Form.Item>

                      <Form.Item label="E-mail" name="edemail2">
                        <Input allowClear style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item label="เบอร์ติดต่อ" name="edphone2">
                        <Input allowClear style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item label="Line-Token" name="edline2">
                        <Input allowClear style={{ width: "100%" }} />
                      </Form.Item>
                    </div>}
              </Form>
            </Card>
          </Drawer>
        </Drawer>
      </div>
    </Affix>
  );
};
export default Header_title;
