import "antd/dist/antd.min.css";

import axios from "axios";
import React, { useState, useEffect } from "react";

import { SaveOutlined, ClearOutlined, PlusOutlined } from "@ant-design/icons";

import { createGlobalStyle } from "styled-components";

import {
  Layout,
  Badge,
  Calendar,
  PageHeader,
  Divider,
  Card,
  Modal,
  Typography,
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  DatePicker
} from "antd";

import moment from "moment";
import "moment/locale/th";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

const { Content } = Layout;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const Research_calendar = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xlistData, _xlistData] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const [xdate, _xdate] = useState("");
  const [xDataday, _xDataday] = useState("");
  const [old_data, _old_data] = useState("");

  const [dateselect, _dateselect] = useState("");
  const [agencyselect, _agencyselect] = useState("");
  const [nameselect, _nameselect] = useState("");
  const [offdateselect, _offdateselect] = useState("");
  const [linkselect, _linkselect] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          axios
            .get(xURL + "find_calendar_event")
            .then(result => {
              // console.log(result.data);
              const Tb1 = [];

              result.data.forEach((i, idx) => {
                Tb1.push({
                  type: i.event_type,
                  agency: i.event_agency,
                  name: i.event_name,
                  offdate: i.event_off_date,
                  link: i.event_link,
                  date: i.event_date
                });
              });

              _xlistData(Tb1);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const Add_Event = () => {
    setIsModalVisible(true);
  };

  const bClearclick = () => {
    form1.resetFields();
  };

  const onFinish = f => {
    // console.log(f,xDataday);
    if (!f.sdate) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันที่นัด", "warning");
    } else if (!f.edevent_agency) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ แหล่งทุน", "warning");
    } else if (!f.edevent_name) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ ชื่อทุน", "warning");
    } else if (!f.edevent_date) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันปิดรับทุน", "warning");
    } else if (!f.edevent_link) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ รายละเอียด", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึก นัดหมาย",
        text: "วันที่ " + xdate,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          insertdata(f);
        }
      });
      // if (old_data) {
      //   // alert("แก้ไข");
      //   swal({
      //     title: "ยืนยันแก้ไข นัดหมาย",
      //     text: "วันที่ " + xdate,
      //     icon: "warning",
      //     buttons: ["ยกเลิก", "ยืนยัน"]
      //   }).then(willOk => {
      //     if (willOk) {
      //       editdata(f);
      //     }
      //   });
      // } else {
      //   // alert("เพิ่ม");

      //   swal({
      //     title: "ยืนยันบันทึก นัดหมาย",
      //     text: "วันที่ " + xdate,
      //     icon: "warning",
      //     buttons: ["ยกเลิก", "ยืนยัน"]
      //   }).then(willOk => {
      //     if (willOk) {
      //       insertdata(f);
      //     }
      //   });
      // }
    }
  };

  const insertdata = data => {
    axios
      .post(xURL + "insert_calendar", {
        event_agency: data.edevent_agency,
        event_name: data.edevent_name,
        event_date: data.edevent_date,
        event_link: data.edevent_link,
        date: data.sdate
      })
      .then(result => {
        swal({
          title: "แจ้งเตือน!",
          text: "บันทึก สำเร็จ",
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const editdata = data => {
    axios
      .post(xURL + "update_calendar", {
        event_agency: data.edevent_agency,
        event_name: data.edevent_name,
        event_date: data.edevent_date,
        event_link: data.edevent_link,
        date: xDataday
      })
      .then(result => {
        swal({
          title: "แจ้งเตือน!",
          text: "แก้ไข สำเร็จ",
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  //   const getListData = value => {
  //     let listData = [];

  //     // switch (value.date()) {
  //     //   case 8:
  //     //     listData = [
  //     //       {
  //     //         type: "warning",
  //     //         content: "This is warning event."
  //     //       }
  //     //     ];
  //     //     break;
  //     //   case 10:
  //     //     listData = [
  //     //       {
  //     //         type: "warning",
  //     //         content: "This is warning event."
  //     //       },
  //     //       {
  //     //         type: "success",
  //     //         content: "This is usual event."
  //     //       },
  //     //       {
  //     //         type: "error",
  //     //         content: "This is error event."
  //     //       }
  //     //     ];
  //     //     break;

  //     //   default:
  //     // }

  //     // console.log(xlistData);
  //     return listData || [];
  //   };

  const getMonthData = value => {
    // if (value.month() === 8) {
    //   return 1394;
    // }
  };

  const onSelect = newValue => {
    axios
      .post(xURL + "find_calendar_event2", {
        zdate: moment(newValue).format("YYYY-MM-DD")
      })
      .then(result => {
        // console.log(result.data[0].event_link);
        if (result.data[0]) {
          _dateselect(moment(result.data[0].event_date).format("DD/MM/YYYY"));
          _agencyselect(result.data[0].event_agency);
          _nameselect(result.data[0].event_name);
          _offdateselect(result.data[0].event_off_date);
          _linkselect(result.data[0].event_link);

          setIsModalVisible2(true);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    // alert(newValue);
    // form1.resetFields();
    // setIsModalVisible(true);
    // _xdate(moment(newValue).format("DD/MM/YYYY"));
    // _xDataday(moment(newValue).format("YYYY-MM-DD"));
    // _old_data("");
    // axios
    //   .post(xURL + "find_calendar_event2", {
    //     zdate: moment(newValue).format("YYYY-MM-DD")
    //   })
    //   .then(result => {
    //     // console.log(result.data[0].event_name);
    //     if (result.data) {
    //       form1.setFieldsValue({
    //         edevent_agency: result.data[0].event_agency,
    //         edevent_name: result.data[0].event_name,
    //         edevent_date: result.data[0].event_off_date,
    //         edevent_link: result.data[0].event_link
    //       });
    //       _old_data(result.data[0].event_name);
    //     } else {
    //       form1.setFieldsValue({
    //         edevent_agency: "",
    //         edevent_name: "",
    //         edevent_date: "",
    //         edevent_link: ""
    //       });
    //       _old_data("");
    //     }
    //   })
    //   .catch(function(error) {
    //     // handle error
    //     console.log("problem here", error);
    //   });
  };

  const onPanelChange = (value, mode) => {
    // console.log(value.format("YYYY-MM-DD"), mode);
    value.stopPropagation();
  };

  const monthCellRender = value => {
    const num = getMonthData(value);
    return num
      ? <div className="notes-month">
          <section>
            {num}
          </section>
          <span>Backlog number</span>
        </div>
      : null;
  };

  const dateCellRender = value => {
    // const listData = getListData(value);

    const datafind = xlistData.filter(
      element =>
        moment(element.date).format("YYYY-MM-DD") ===
        moment(value).format("YYYY-MM-DD")
    );
    const listData = datafind;

    return (
      <div>
        {listData.map(
          item =>
            <p style={{ backgroundColor: "#b5f7b9", marginBottom: "1px" }}>
              <Badge status={item.type} /> <b>{item.agency} </b>
            </p>
          // <div>
          //   <p style={{ backgroundColor: "", marginBottom: "1px" }}>
          //     <Badge status={item.type} /> <b>แหล่งทุน : </b> {item.agency}
          //   </p>
          //   <p style={{ backgroundColor: "", marginBottom: "1px" }}>
          //     <Badge status={item.type} /> <b>ชื่อทุน : </b> {item.name}
          //   </p>
          //   <p style={{ backgroundColor: "", marginBottom: "1px" }}>
          //     <Badge status={item.type} /> <b>วันปิดรับทุน : </b> {item.offdate}
          //   </p>
          //   <p style={{ backgroundColor: "" }}>
          //     <Badge status={item.type} /> <b>รายละเอียด : </b> {item.link}
          //   </p>
          // </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{
          padding: "0 20px",
          marginTop: 64
        }}
      >
        <PageHeader
          className="site-page-header"
          title="Research Grant Application Calendar"
        />

        <Divider />

        <Row>
          <Space>
            <Button ghost type="primary" onClick={Add_Event}>
              <PlusOutlined /> เพิ่มนัดหมาย
            </Button>
          </Space>
        </Row>
        <br />

        <Card style={{ borderColor: "#62b8ff" }}>
          <Calendar
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
            onSelect={onSelect}
            onPanelChange={onPanelChange}
          />
        </Card>
      </Content>

      <br />
      <br />
      <Footer_last />

      <Modal
        title={<Text style={{ fontWeight: "bold" }}>นัดหมาย</Text>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Form form={form1} onFinish={onFinish} initialValues={{}}>
          <Form.Item
            label="วันที่นัด&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            name="sdate"
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>

          <Form.Item
            label="แหล่งทุน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            name="edevent_agency"
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="ชื่อทุน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            name="edevent_name"
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item label="วันปิดรับทุน&nbsp;&nbsp;" name="edevent_date">
            <Input allowClear />
          </Form.Item>

          <Form.Item label="รายละเอียด&nbsp;&nbsp;" name="edevent_link">
            <Input allowClear />
          </Form.Item>

          <Divider />

          <Row justify="center">
            <Space>
              <Button ghost type="primary" htmlType="submit">
                <SaveOutlined /> บันทึก
              </Button>

              <Button ghost type="danger" onClick={bClearclick}>
                <ClearOutlined /> เคลียร์
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={<Text style={{ fontWeight: "bold" }}>รายละเอียดนัดหมาย</Text>}
        visible={isModalVisible2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
        width={1200}
      >
        <Form form={form2} initialValues={{}}>
          <Form.Item
            label="วันที่นัด&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            name="sdate"
          >
            {dateselect}
          </Form.Item>

          <Form.Item
            label="แหล่งทุน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            name="edevent_agency"
          >
            {agencyselect}
          </Form.Item>

          <Form.Item
            label="ชื่อทุน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            name="edevent_name"
          >
            {nameselect}
          </Form.Item>

          <Form.Item label="วันปิดรับทุน&nbsp;&nbsp;" name="edevent_date">
            {offdateselect}
          </Form.Item>

          <Form.Item label="รายละเอียด&nbsp;&nbsp;" name="edevent_link">
            <a href={linkselect} target="_blank">
              {linkselect}
            </a>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Research_calendar;
