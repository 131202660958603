import React, { useState, useEffect } from "react";
import axios from "axios";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import {
  Layout,
  Row,
  Col,
  Space,
  Divider,
  PageHeader,
  List,
  Tooltip,
  Card,
  Typography
} from "antd";

import { createGlobalStyle } from "styled-components";

import ThIcon from "../pics/th_icon.png";
import EnIcon from "../pics/en_icon.png";
import AddImg from "../pics/add-image.png";
import EditImg from "../pics/edit-image.png";
import Logo from "../pics/RIHES-Logo1.png";

import "./index.css";

const { Content } = Layout;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_rihes_list_th = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xTitle, _xTitle] = useState();
  const [xLng, _xLng] = useState();

  const [dataSource, _dataSource] = useState();
  const [dataSource2, _dataSource2] = useState();

  const [dataSource3, _dataSource3] = useState();
  const [dataSource4, _dataSource4] = useState();

  useEffect(() => {
    var url = new URL(window.location.href);

    if (url.searchParams.get("pages") == "IDSU") {
      _xTitle("ศูนย์วิจัยโรคติดเชื้อและสารเสพติด");
      _xLng("IDSU");

      Getdata("IDSU");
    } else if (url.searchParams.get("pages") == "CMBI") {
      _xTitle("ศูนย์วิจัยชีวโมเลกุลและเซลล์วิทยา");
      _xLng("CMBI");

      Getdata("CMBI");
    } else if (url.searchParams.get("pages") == "NINE") {
      _xTitle("ศูนย์วิจัยโรคไม่ติดเชื้อและอนามัยสิ่งแวดล้อม");
      _xLng("NINE");

      Getdata("NINE");
    } else if (url.searchParams.get("pages") == "SHSR") {
      _xTitle("สำนักวิชาการวิจัยวิทยาศาสตร์สุขภาพ");
      _xLng("SHSR");

      Getdata("SHSR");
    }
  }, []);

  const Getdata = zdata => {
    let xcenter = "";

    if (zdata == "IDSU") {
      xcenter = "1";
    } else if (zdata == "CMBI") {
      xcenter = "2";
    } else if (zdata == "NINE") {
      xcenter = "3";
    } else if (zdata == "SHSR") {
      xcenter = "4";
    }

    axios
      .post(xURL + "getResearch_rihes", {
        center: xcenter,
        order: 1
      })
      .then(result => {
        const Tb1 = [];

        let xresearcher_expertise = "",
          xpositionex = "";

        result.data.forEach((i, idx) => {
          if (!i.researcher_expertise) {
            xresearcher_expertise = ["-"];
          } else {
            if (i.researcher_expertise.split("|").length > 1) {
              xresearcher_expertise = i.researcher_expertise.split("|");
            } else {
              xresearcher_expertise = [i.researcher_expertise];
            }
          }

          if (!i.positionex) {
            xpositionex = ["-"];
          } else {
            if (i.positionex.split("|").length > 0) {
              xpositionex = i.positionex.split("|");
            } else {
              xpositionex = i.positionex;
            }
          }

          Tb1.push({
            key: idx,
            id: i.id,
            name: i.name,
            position: i.position,
            position_aca: i.position_aca,
            positionex: xpositionex,
            email: i.email,
            researcher_expertise: xresearcher_expertise,
            picname: i.picname
          });
        });

        _dataSource(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "getResearch_rihes", {
        center: xcenter,
        order: 2
      })
      .then(result => {
        const Tb1 = [];

        const Tb3 = [],
          Tb4 = [];

        let xresearcher_expertise = "",
          xpositionex = "";

        result.data.forEach((i, idx) => {
          if (!i.researcher_expertise) {
            xresearcher_expertise = ["-"];
          } else {
            if (i.researcher_expertise.split("|").length > 0) {
              xresearcher_expertise = i.researcher_expertise.split("|");
            } else {
              xresearcher_expertise = [i.researcher_expertise];
            }
          }

          if (!i.positionex) {
            xpositionex = ["-"];
          } else {
            if (i.positionex.split("|").length > 0) {
              xpositionex = i.positionex.split("|");
            } else {
              xpositionex = i.positionex;
            }
          }

          if (xcenter !== "4") {
            if (idx > 0) {
              Tb1.push({
                key: idx,
                id: i.id,
                name: i.name,
                position: i.position,
                position_aca: i.position_aca,
                positionex: xpositionex,
                email: i.email,
                researcher_expertise: xresearcher_expertise,
                picname: i.picname
              });
            }
          } else {
            if (idx > 1) {
              Tb1.push({
                key: idx,
                id: i.id,
                name: i.name,
                position: i.position,
                position_aca: i.position_aca,
                positionex: xpositionex,
                email: i.email,
                researcher_expertise: xresearcher_expertise,
                picname: i.picname
              });
            }
          }

          if (xcenter == "1") {
            if (idx >= 1 && idx <= 4) {
              Tb3.push({
                key: idx,
                id: i.id,
                name: i.name,
                position: i.position,
                position_aca: i.position_aca,
                positionex: xpositionex,
                email: i.email,
                researcher_expertise: xresearcher_expertise,
                picname: i.picname
              });
            }

            if (idx > 4) {
              Tb4.push({
                key: idx,
                id: i.id,
                name: i.name,
                position: i.position,
                position_aca: i.position_aca,
                positionex: xpositionex,
                email: i.email,
                researcher_expertise: xresearcher_expertise,
                picname: i.picname
              });
            }
          }
        });

        _dataSource2(Tb1);

        _dataSource3(Tb3);
        _dataSource4(Tb4);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <StyledForm />

      {/* <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      /> */}

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
        </style>

        {xLng == "IDSU"
          ? <div
              style={{
                marginRight: "100px",
                marginTop: "-40px",
                marginBottom: "30px"
              }}
            >
              <img src={Logo} alt="logo_icon" width="250" height="100" />
            </div>
          : <div />}

        <div
          style={{ float: "right", marginRight: "50px", marginTop: "-40px" }}
        >
          <Space>
            <p
              style={{
                fontSize: "16px",
                marginBottom: "5px",
                fontFamily: "Bai Jamjuree",
                fontWeight: "600"
              }}
            >
              ภาษา :
            </p>
            <div className="img-lang">
              <img
                src={ThIcon}
                alt="th_icon"
                width="40"
                height="40"
                onClick={() => {
                  window.open("/Research_rihes_list_th?pages=" + xLng, "_self");
                }}
              />
            </div>

            <div className="img-lang">
              <img
                className="img-lang"
                src={EnIcon}
                alt="en_icon"
                width="40"
                height="40"
                style={{ opacity: "0.3" }}
                onClick={() => {
                  window.open("/Research_rihes_list_en?pages=" + xLng, "_self");
                }}
              />
            </div>
          </Space>
        </div>

        {/* <PageHeader className="site-page-header" title={xTitle} /> */}
        <h2
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Bai Jamjuree"
          }}
        >
          {xTitle}
        </h2>
        <Divider />

        {xLng == "IDSU"
          ? <div>
              <List
                size="large"
                itemLayout="vertical"
                bordered
                dataSource={dataSource}
                renderItem={item =>
                  <List.Item key={item.name}>
                    <div
                      style={{
                        maxWidth: "fit-content",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    >
                      <Row justify="center">
                        <Col span={9} xs={24} sm={24} md={24} lg={11} xl={8}>
                          <div className="pic-rihes-list">
                            <Row>
                              <img
                                width={170}
                                height={235}
                                alt="profile"
                                src={`${xURL}file_research/${item.picname}`}
                              />
                            </Row>

                            <div
                              style={{
                                marginTop: "15px",
                                marginBottom: "-15px"
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Bai Jamjuree",
                                  cursor: "pointer",
                                  color: "#37ace7"
                                }}
                                onClick={() => {
                                  window.open(
                                    "/Research_rihes_detail_th?pages=" +
                                      xLng +
                                      "&rid=" +
                                      item.id,
                                    "_blank"
                                  );
                                }}
                              >
                                ข้อมูลเพิ่มเติม...
                              </p>
                            </div>
                          </div>
                        </Col>

                        <Col span={15} xs={24} sm={24} md={24} lg={12} xl={16}>
                          <div
                            style={{
                              marginLeft: "10px",
                              fontSize: "15px",
                              fontFamily: "Bai Jamjuree"
                            }}
                          >
                            <b>ชื่อ-สกุล</b> : {item.name} <br />
                            <b>ตำแหน่งปฏิบัติงาน</b> : {item.position} <br />
                            <b>ตำแหน่งทางวิชาการ</b> : {item.position_aca}{" "}
                            <br />
                            {item.positionex[0] !== "-"
                              ? <div>
                                  <b>ตำแหน่งบริหาร</b> :{" "}
                                  {item.positionex.map((item, index) =>
                                    <span>
                                      {item}
                                      <br />
                                    </span>
                                  )}
                                </div>
                              : <div />}
                            <b>อีเมล์</b> : {item.email} <br />
                            <b>ความเชี่ยวชาญ</b> :{" "}
                            {item.researcher_expertise.map(
                              (item, index) =>
                                // <span>
                                //   {item}
                                //   <br />
                                // </span>
                                index > 0
                                  ? <span>
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>
                                          {item}
                                        </li>
                                      </ul>
                                    </span>
                                  : <div />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </List.Item>}
              />

              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "Bai Jamjuree",
                  fontSize: "20px",
                  marginTop: "30px"
                }}
              >
                นักวิจัยอาวุโส
              </h2>

              <br />

              <List
                grid={{
                  gutter: 5,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 2
                }}
                size="large"
                itemLayout="vertical"
                bordered
                dataSource={dataSource3}
                renderItem={item =>
                  <List.Item key={item.name}>
                    <Row>
                      <Col span={9} xs={24} sm={24} md={24} lg={11} xl={8}>
                        <div>
                          <Row>
                            <img
                              width={170}
                              height={235}
                              alt="profile"
                              src={`${xURL}file_research/${item.picname}`}
                            />
                          </Row>
                        </div>
                      </Col>

                      <Col span={15} xs={24} sm={24} md={24} lg={12} xl={16}>
                        <div
                          style={{
                            // marginLeft: "10px",
                            // marginRight: "-20px",
                            fontSize: "15px",
                            fontFamily: "Bai Jamjuree"
                          }}
                        >
                          <b>ชื่อ-สกุล</b> : {item.name} <br />
                          <b>ตำแหน่งปฏิบัติงาน</b> : {item.position} <br />
                          <b>ตำแหน่งทางวิชาการ</b> : {item.position_aca} <br />
                          {item.positionex[0] !== "-"
                            ? <div>
                                <b>ตำแหน่งบริหาร</b> :{" "}
                                {item.positionex.map((item, index) =>
                                  <span>
                                    {item}
                                    <br />
                                  </span>
                                )}
                              </div>
                            : <div />}
                          <b>อีเมล์</b> : {item.email} <br />
                          <b>ความเชี่ยวชาญ</b> :{" "}
                          {item.researcher_expertise.map(
                            (item, index) =>
                              // <span>
                              //   {item}
                              //   <br />
                              // </span>
                              index > 0
                                ? <span>
                                    <ul style={{ listStyleType: "square" }}>
                                      <li>
                                        {item}
                                      </li>
                                    </ul>
                                  </span>
                                : <div />
                          )}
                        </div>
                      </Col>
                    </Row>

                    <div style={{ marginTop: "15px", marginBottom: "-15px" }}>
                      <p
                        style={{
                          fontSize: "14px",
                          fontFamily: "Bai Jamjuree",
                          cursor: "pointer",
                          color: "#37ace7"
                        }}
                        onClick={() => {
                          window.open(
                            "/Research_rihes_detail_th?pages=" +
                              xLng +
                              "&rid=" +
                              item.id,
                            "_blank"
                          );
                        }}
                      >
                        ข้อมูลเพิ่มเติม...
                      </p>
                    </div>

                    <Divider />
                  </List.Item>}
              />

              <h2
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "Bai Jamjuree",
                  fontSize: "20px",
                  marginTop: "30px"
                }}
              >
                นักวิจัย
              </h2>

              <br />

              <List
                grid={{
                  gutter: 5,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 2
                }}
                size="large"
                itemLayout="vertical"
                bordered
                dataSource={dataSource4}
                renderItem={item =>
                  <List.Item key={item.name}>
                    <Row>
                      <Col span={9} xs={24} sm={24} md={24} lg={11} xl={8}>
                        <div>
                          <Row>
                            <img
                              width={170}
                              height={235}
                              alt="profile"
                              src={`${xURL}file_research/${item.picname}`}
                            />
                          </Row>
                        </div>
                      </Col>

                      <Col span={15} xs={24} sm={24} md={24} lg={12} xl={16}>
                        <div
                          style={{
                            // marginLeft: "10px",
                            // marginRight: "-20px",
                            fontSize: "15px",
                            fontFamily: "Bai Jamjuree"
                          }}
                        >
                          <b>ชื่อ-สกุล</b> : {item.name} <br />
                          <b>ตำแหน่งปฏิบัติงาน</b> : {item.position} <br />
                          <b>ตำแหน่งทางวิชาการ</b> : {item.position_aca} <br />
                          {item.positionex[0] !== "-"
                            ? <div>
                                <b>ตำแหน่งบริหาร</b> :{" "}
                                {item.positionex.map((item, index) =>
                                  <span>
                                    {item}
                                    <br />
                                  </span>
                                )}
                              </div>
                            : <div />}
                          <b>อีเมล์</b> : {item.email} <br />
                          <b>ความเชี่ยวชาญ</b> :{" "}
                          {item.researcher_expertise.map(
                            (item, index) =>
                              // <span>
                              //   {item}
                              //   <br />
                              // </span>
                              index > 0
                                ? <span>
                                    <ul style={{ listStyleType: "square" }}>
                                      <li>
                                        {item}
                                      </li>
                                    </ul>
                                  </span>
                                : <div />
                          )}
                        </div>
                      </Col>
                    </Row>

                    <div style={{ marginTop: "15px", marginBottom: "-15px" }}>
                      <p
                        style={{
                          fontSize: "14px",
                          fontFamily: "Bai Jamjuree",
                          cursor: "pointer",
                          color: "#37ace7"
                        }}
                        onClick={() => {
                          window.open(
                            "/Research_rihes_detail_th?pages=" +
                              xLng +
                              "&rid=" +
                              item.id,
                            "_blank"
                          );
                        }}
                      >
                        ข้อมูลเพิ่มเติม...
                      </p>
                    </div>

                    <Divider />
                  </List.Item>}
              />
            </div>
          : <div style={{ margin: "0 20px" }}>
              <List
                size="large"
                itemLayout="vertical"
                bordered
                dataSource={dataSource}
                renderItem={item =>
                  <List.Item key={item.name}>
                    <div
                      style={{
                        maxWidth: "fit-content",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    >
                      <Row justify="center" style={{ width: "80%" }}>
                        <Col span={9} xs={24} sm={24} md={24} lg={11} xl={8}>
                          <div className="pic-rihes-list">
                            <Row>
                              <img
                                width={170}
                                height={235}
                                alt="profile"
                                src={`${xURL}file_research/${item.picname}`}
                              />
                            </Row>
                            {/* <Row>
                          {!item.picname
                            ? <Tooltip title="เพิ่มรูปภาพ">
                                <img
                                  style={{
                                    marginTop: "5px",
                                    cursor: "pointer"
                                  }}
                                  width={20}
                                  height={20}
                                  alt="addImage"
                                  src={AddImg}
                                  onClick={() => {
                                    window.open(
                                      "/Research_Pic?type=Add&rid=" +
                                        item.id +
                                        "&pages=" +
                                        xLng,
                                      "_self"
                                    );
                                  }}
                                />
                              </Tooltip>
                            : <Tooltip title="แก้ไขรูปภาพ">
                                <img
                                  style={{
                                    marginTop: "5px",
                                    cursor: "pointer"
                                  }}
                                  width={20}
                                  height={20}
                                  alt="editImage"
                                  src={EditImg}
                                  onClick={() => {
                                    window.open(
                                      "/Research_Pic?type=Edit&rid=" +
                                        item.id +
                                        "&pages=" +
                                        xLng,
                                      "_self"
                                    );
                                  }}
                                />
                              </Tooltip>}
                        </Row> */}

                            <div
                              style={{
                                marginTop: "15px",
                                marginBottom: "-15px"
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Bai Jamjuree",
                                  cursor: "pointer",
                                  color: "#37ace7"
                                }}
                                onClick={() => {
                                  window.open(
                                    "/Research_rihes_detail_th?pages=" +
                                      xLng +
                                      "&rid=" +
                                      item.id,
                                    "_blank"
                                  );
                                }}
                              >
                                ข้อมูลเพิ่มเติม...
                              </p>
                            </div>
                          </div>
                        </Col>

                        <Col span={15} xs={24} sm={24} md={24} lg={12} xl={16}>
                          <div
                            style={{
                              marginLeft: "10px",
                              fontSize: "15px",
                              fontFamily: "Bai Jamjuree"
                            }}
                          >
                            <b>ชื่อ-สกุล</b> : {item.name} <br />
                            <b>ตำแหน่งปฏิบัติงาน</b> : {item.position} <br />
                            <b>ตำแหน่งทางวิชาการ</b> : {item.position_aca}{" "}
                            <br />
                            {item.positionex[0] !== "-"
                              ? <div>
                                  <b>ตำแหน่งบริหาร</b> :{" "}
                                  {item.positionex.map((item, index) =>
                                    <span>
                                      {item}
                                      <br />
                                    </span>
                                  )}
                                </div>
                              : <div />}
                            <b>อีเมล์</b> : {item.email} <br />
                            <b>ความเชี่ยวชาญ</b> :{" "}
                            {item.researcher_expertise.map(
                              (item, index) =>
                                // <span>
                                //   {item}
                                //   <br />
                                // </span>
                                index > 0
                                  ? <span>
                                      <ul style={{ listStyleType: "square" }}>
                                        <li>
                                          {item}
                                        </li>
                                      </ul>
                                    </span>
                                  : <div />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </List.Item>}
              />

              <br />

              <List
                grid={{
                  gutter: 5,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 3,
                  xxl: 3
                }}
                size="large"
                itemLayout="vertical"
                bordered
                dataSource={dataSource2}
                renderItem={item =>
                  <List.Item key={item.name}>
                    <Row>
                      <Col span={9} xs={24} sm={24} md={24} lg={11} xl={10}>
                        <div>
                          <Row>
                            <img
                              width={170}
                              height={235}
                              alt="profile"
                              src={`${xURL}file_research/${item.picname}`}
                            />
                          </Row>
                          {/* <Row>
                        {!item.picname
                          ? <Tooltip title="เพิ่มรูปภาพ">
                              <img
                                style={{
                                  marginTop: "5px",
                                  cursor: "pointer"
                                }}
                                width={20}
                                height={20}
                                alt="addImage"
                                src={AddImg}
                                onClick={() => {
                                  window.open(
                                    "/Research_Pic?type=Add&rid=" +
                                      item.id +
                                      "&pages=" +
                                      xLng,
                                    "_self"
                                  );
                                }}
                              />
                            </Tooltip>
                          : <Tooltip title="แก้ไขรูปภาพ">
                              <img
                                style={{
                                  marginTop: "5px",
                                  cursor: "pointer"
                                }}
                                width={20}
                                height={20}
                                alt="editImage"
                                src={EditImg}
                                onClick={() => {
                                  window.open(
                                    "/Research_Pic?type=Edit&rid=" +
                                      item.id +
                                      "&pages=" +
                                      xLng,
                                    "_self"
                                  );
                                }}
                              />
                            </Tooltip>}
                      </Row> */}
                        </div>
                      </Col>

                      <Col span={15} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div
                          style={{
                            // marginLeft: "10px",
                            // marginRight: "-20px",
                            fontSize: "15px",
                            fontFamily: "Bai Jamjuree"
                          }}
                        >
                          <b>ชื่อ-สกุล</b> : {item.name} <br />
                          <b>ตำแหน่งปฏิบัติงาน</b> : {item.position} <br />
                          <b>ตำแหน่งทางวิชาการ</b> : {item.position_aca} <br />
                          {item.positionex[0] !== "-"
                            ? <div>
                                <b>ตำแหน่งบริหาร</b> :{" "}
                                {item.positionex.map((item, index) =>
                                  <span>
                                    {item}
                                    <br />
                                  </span>
                                )}
                              </div>
                            : <div />}
                          <b>อีเมล์</b> : {item.email} <br />
                          <b>ความเชี่ยวชาญ</b> :{" "}
                          {item.researcher_expertise.map(
                            (item, index) =>
                              // <span>
                              //   {item}
                              //   <br />
                              // </span>
                              index > 0
                                ? <span>
                                    <ul style={{ listStyleType: "square" }}>
                                      <li>
                                        {item}
                                      </li>
                                    </ul>
                                  </span>
                                : <div />
                          )}
                        </div>
                      </Col>
                    </Row>

                    <div style={{ marginTop: "15px", marginBottom: "-15px" }}>
                      <p
                        style={{
                          fontSize: "14px",
                          fontFamily: "Bai Jamjuree",
                          cursor: "pointer",
                          color: "#37ace7"
                        }}
                        onClick={() => {
                          window.open(
                            "/Research_rihes_detail_th?pages=" +
                              xLng +
                              "&rid=" +
                              item.id,
                            "_blank"
                          );
                        }}
                      >
                        ข้อมูลเพิ่มเติม...
                      </p>
                    </div>

                    <Divider />
                  </List.Item>}
              />
            </div>}
      </Content>

      <div style={{ height: "100px" }} />

      {/* <div style={{ height: "200px" }} />

      <Footer_last /> */}
    </React.Fragment>
  );
};

export default Research_rihes_list_th;
